import {createAction} from "@cobuildlab/react-simple-state";
import {
  createSectionErrorEvent,
  createSectionEvent, deleteSectionErrorEvent, deleteSectionEvent,
  fetchSectionErrorEvent,
  fetchSectionEvent, 
  fetchSectionListErrorEvent, 
  fetchSectionListEvent, 
  updateSectionErrorEvent,
  updateSectionEvent
} from "./section-events";
import { PostType, SettingsType} from "../../shared/types";
import axios from "axios";
import moment from "moment";

export const  createSection = createAction(
  createSectionEvent,
  createSectionErrorEvent,
  async (settings: SettingsType, selectedGrid: string, postItemsSelected: Array<PostType>,postPublish:any, sectionName: string)=>{

    const formData = new FormData();
    const selectedItems = postItemsSelected.reduce((acc, curr)=>{
      // @ts-ignore
      acc.push({ID:curr?.ID || '', position:curr?.position});
        
      return acc; 
    },[]);
      
    formData.append('selectedItems',JSON.stringify(selectedItems ));
    formData.append('selectedGrid',selectedGrid);
    formData.append('sectionName',sectionName);
    formData.append('postPublish',moment(postPublish).format('YYYY-MM-DD HH:mm:ss'));

    return  await axios.post(`${settings.homeUrl}/wp-json/wp/v2/section/create`,formData,{
      headers:{
        'content-type': 'multipart/form-data'
      }
    });
  }
);

export const  updateSection = createAction(
  updateSectionEvent,
  updateSectionErrorEvent,
  async (
    settings: SettingsType, 
    selectedGrid: string, 
    postItemsSelected: Array<PostType>,
    postPublish:any, 
    sectionName: string
  )=>{

    const formData = new FormData();
    const selectedItems = postItemsSelected.reduce((acc, curr)=>{
      // @ts-ignore
      acc.push({ID:curr?.ID || '', position:curr?.position});

      return acc;
    },[]);

    formData.append('selectedItems',JSON.stringify(selectedItems ));
    formData.append('selectedGrid',selectedGrid);
    formData.append('sectionName',sectionName);
    formData.append('postPublish',moment(postPublish).format('YYYY-MM-DD HH:mm:ss'));
    // @ts-ignore
    formData.append('sectionId',settings?.sectionId);
    
    return  await axios.post(`${settings.homeUrl}/wp-json/wp/v2/section/update`,formData,{
      headers:{
        'content-type': 'multipart/form-data'
      }
    });
  }
);


export const  fetchSection = createAction(
  fetchSectionEvent,
  fetchSectionErrorEvent,
  async (settings: SettingsType)=>{

    return await axios.get(`${settings.homeUrl}/wp-json/wp/v2/section/fetch`,{
      params: {
        sectionId: settings.sectionId
      }
    });
  }
);

export const  fetchSectionList = createAction(
  fetchSectionListEvent,
  fetchSectionListErrorEvent,
  async (settings: SettingsType, filter: {page:number, perPage:number})=>{

    return await axios.get(`${settings.homeUrl}/wp-json/wp/v2/section/list`,{
      params: {
        ...filter
      }
    });
  }
);

export const deleteSection = createAction(
  deleteSectionEvent,
  deleteSectionErrorEvent,
  async (
    settings: SettingsType,
    sectionId: string
  )=> {

    const formData = new FormData();
    formData.append('sectionId',sectionId);

    return await axios.post(`${settings.homeUrl}/wp-json/wp/v2/section/delete`,
      formData,{
        headers:{
          'content-type': 'multipart/form-data'
        }
      });
  }
);