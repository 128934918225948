import React, {ReactNode} from 'react';
import {SettingsType} from "../../../../types";

type BoxContainerProps = {
    children: ReactNode;
    margin?: number;
}

export const FrontContainerBox: React.FC<BoxContainerProps> = ({
  children,
  margin= 16,
}) =>{

  return(
    <div style={{marginBottom:margin, cursor:'pointer'}}>
      {children}
    </div>
  );
};