import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  TablePagination as MDTablePagination,
  TablePaginationProps,
} from '@material-ui/core';
import {Container} from "../../../shared/components/Container";

const useMDStyles = makeStyles({
  root: {
    borderBottom: 0,
  },
  paginationToolbar: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  hidden: {
    display: 'none',
  },
});

/**
 * Basic table paging component.
 *
 * @param {TablePaginationProps} props - Props of TablePagination.
 * @returns The Pagination Component.
 */

export const TablePagination: React.FC<TablePaginationProps> = (props) => {
  const classes = useMDStyles();
  return (
  /* 
          The widget has a 'float' property that can only be treated by applying
          display: flex and centering with justify to center (Container) and using
          Box child with width 'auto
         */
    <Container justify="center">
      <Box>
        <MDTablePagination
          {...props}
          classes={{
            root: classes.root,
            toolbar: classes.paginationToolbar,
            spacer: classes.hidden, // removes spacer that fills empty space
            caption: classes.hidden, // Remove 'Rows per page: {}-{} of {}'
            selectRoot: classes.hidden,
          }}
        />
      </Box>
    </Container>
  );
};