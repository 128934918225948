import React, {ReactNode} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Slide} from '@material-ui/core';
import {TransitionProps} from '@material-ui/core/transitions';
import {makeStyles} from '@material-ui/core/styles';
import {ButtonDefault} from '../buttons/buttons';

const useStyles = makeStyles({
  root:{
    minWidth: 500,
    minHeight: 50,
    maxHeight: 300,
    overflow: 'auto',
    '@media only screen and (max-width: 500px)':{
      minWidth:'100%!important',
      maxHeight: 400,
    }
  },
  dialog:{
    '&> div > div':{
      maxWidth:700,
      padding:0,
      '@media only screen and (max-width: 500px)':{
        maxWidth:'100%!important'
      }
    }
  },
  buttons:{


    '@media only screen and (max-width: 500px)':{
      justifyContent:'center',
      width:'100%'
    }
    
      
  }  
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type DefaultDialogsProps = {
    handleClose: () => void;
    open: boolean;
    isLoading: boolean;
    title: string;
    cancelText: string;
    onYesText: string;
    children: ReactNode;
    onYes: () => void;
    
}

export const DefaultDialogs: React.FC<DefaultDialogsProps> = (
  {
    onYes,
    handleClose, open,title, children,
    cancelText,onYesText,
    isLoading
  }
) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={Transition}
      className={classes.dialog}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent className={classes.root}>
        <DialogContentText id="alert-dialog-description" style={{textAlign:'center'}}>
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions 
        style={{padding:'16px 24px'}}
        className={classes.buttons}
      >
        {
          cancelText && (
            <ButtonDefault
              onClick={handleClose}
              color="primary"
              variant='contained'
              style={{width:130}}
            >
              {cancelText}
            </ButtonDefault>
          )
        }
     
        {
          onYesText && (
            <ButtonDefault
              onClick={()=>onYes()}
              color="primary"
              variant='outlined'
              autoFocus
              isLoading={isLoading}
              style={{width:130}}
            >
              {onYesText}
            </ButtonDefault>
          )
        }  

      </DialogActions>
    </Dialog>

  );
};