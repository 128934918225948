import React from "react";
import {BoxContainer} from "./components/BoxContainer";
import {BoxItem} from "../../box/BoxItem";
import {GridRowType} from "../../types";
import {Grid} from "@material-ui/core";
import {SkeletonBox} from "./skeletons/SkeletonBox";

export const GridOne: React.FC<GridRowType> = ({
  items, onChangeGrid,name
}) => {
    
  const item1 = items.find((item)=>{return item.position === 1;});
  const item2 = items.find((item)=>{return item.position === 2;});
  const item3 = items.find((item)=>{return item.position === 3;});
  const item4 = items.find((item)=>{return item.position === 4;});

  return (
    <Grid container  spacing={3}>
      <Grid item md={4}>
        <BoxContainer position={1} name={name}>
          {
            item1 ?(
              <BoxItem item={item1} onChangeGrid={onChangeGrid} paddingPaper={false}/>
            ):(
              <SkeletonBox/>  
            )
          }
            
        </BoxContainer>
        <BoxContainer position={3} name={name}>
          {
            item3 ? (
              <BoxItem item={item3} onChangeGrid={onChangeGrid} paddingPaper={false}/>
            ):(
              <SkeletonBox/>  
            )
          }
        </BoxContainer>
        
      </Grid>
      <Grid item md={8}>
        <BoxContainer position={2} name={name}>
          {
            item2 ? (
              <BoxItem item={item2} onChangeGrid={onChangeGrid} height={277} paddingPaper={false}/>

            ):(
              <SkeletonBox height={277}/>
            )
          }
         
        </BoxContainer>
        <BoxContainer position={4} name={name}>
          {
            item4 ? (
              <BoxItem item={item4} onChangeGrid={onChangeGrid} paddingPaper={false} horizontal/>
            ):(
              <SkeletonBox horizontal/>
            )
          }
        </BoxContainer>
      </Grid>
    </Grid>
  );
};