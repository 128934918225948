import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {CreateSectionView} from './modules/section/CreateSectionView';
import {SECTIONS, SETTINGS} from "./shared/contansts";
import {UpdateSectionView} from "./modules/section/UpdateSectionView";
import {SectionListView} from "./modules/section/SectionListView";
import {SectionView} from "./modules/section/SectionView";
import {MuiThemeProvider} from "@material-ui/core/styles";
import theme from "./shared/configs/theme";
import CssBaseline from "@material-ui/core/CssBaseline";
import {ToastContainer} from "./shared/components/toasts/ToastContainer";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const createSectionView: HTMLElement | null  = document.getElementById('create-section-view');
const updateSectionView: HTMLElement | null  = document.getElementById('update-section-view');
const sectionListView: HTMLElement | null  = document.getElementById('section-list-view');
const sectionsList: HTMLElement | null  = document.getElementById('sectionsList');
const sectionView: HTMLElement | null  = document.getElementById('section-view-1');

if(createSectionView){
  const settings = JSON.parse( createSectionView.getAttribute('data-create-settings') || JSON.stringify(SETTINGS) )  ;

  ReactDOM.render(
    <React.StrictMode>
      <MuiThemeProvider theme={theme}>
        <CssBaseline/>
        <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
        <CreateSectionView settings={settings}/>
      </MuiThemeProvider>
    </React.StrictMode>,
    document.getElementById('create-section-view')
  );
}

if(updateSectionView){
  const settings = JSON.parse( updateSectionView.getAttribute('data-update-settings') || JSON.stringify(SETTINGS) ) ;
  ReactDOM.render(
    <React.StrictMode>
      <MuiThemeProvider theme={theme}>
        <CssBaseline/>
        <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
        <UpdateSectionView settings={settings}/>
      </MuiThemeProvider>
    </React.StrictMode>,
    document.getElementById('update-section-view')
  );
}

if(sectionListView){
  const settings = JSON.parse( sectionListView.getAttribute('data-section-list-settings') || JSON.stringify(SETTINGS) );
  ReactDOM.render(
    <React.StrictMode>
      <MuiThemeProvider theme={theme}>
        <CssBaseline/>
        <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
        <SectionListView settings={settings}/>
      </MuiThemeProvider>
    </React.StrictMode>,
    document.getElementById('section-list-view')
  );
}



if(sectionsList){
  const sections: Array<{sectionId: number}> = JSON.parse( sectionsList.getAttribute('data-sections-list-settings') || JSON.stringify(SECTIONS) );

  sections.forEach((section)=>{

    const sectionView: HTMLElement | null  = document.getElementById(`section-view-${section.sectionId}`);

    if(sectionView){
      const data = JSON.parse( sectionView.getAttribute('data-section-settings') || JSON.stringify({selectedGrid:'', postItemsSelected:{}}) );
      ReactDOM.render(
        <React.StrictMode>
          <MuiThemeProvider theme={theme}>
            <CssBaseline/>
            <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
            <SectionView data={{...data, sectionId: section.sectionId}}/>
          </MuiThemeProvider>
        </React.StrictMode>,
        document.getElementById(`section-view-${section.sectionId}`)
      );
    }
  });
}
