import axios from "axios";
import {createAction} from "@cobuildlab/react-simple-state";
import {fetchPostsListErrorEvent, fetchPostsListEvent} from "./posts-events";
import {FilterType, PostType, SettingsType} from "../../shared/types";

export const  fetchPostsList = createAction(
  fetchPostsListEvent,
  fetchPostsListErrorEvent,
  async (settings: SettingsType, filter:FilterType, postItemsSelected: PostType[])=>{
      
    const selected = postItemsSelected.reduce((acc : string[], curr)=>{
      acc.push(curr?.ID || '');
      return acc;
    },[]).join(',');
      
    return  await axios.get(`${settings.homeUrl}/wp-json/wp/v2/posts/list`,{
      params: {...filter, selected}
    });
  }
);