import React, {ReactNode} from 'react';
import {useDrop} from "react-dnd";

type BoxContainerProps = {
    children: ReactNode;
    position: number;
    name: string;
    margin?: number
}

export const BoxContainer: React.FC<BoxContainerProps> = ({
  children,
  position,
  name, 
  margin= 16
}) =>{

  const [{canDrop, isOver}, drop] = useDrop({
    accept: 'box-item-type',
    drop: () => ({name, position}),
    collect: (monitor: { isOver: () => any; canDrop: () => any; }) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });


  return(
    <div ref={drop} style={{marginBottom:margin}}>
      {children}
    </div>
  );
};