import React from "react";
import {FrontGridType} from "../../../types";
import {Grid , useMediaQuery} from "@material-ui/core";
import {FrontBoxItem} from "./components/FrontBoxItem";
import {FrontContainerBox} from "./components/FrontContainerBox";
import {onClickPost} from "../../../utils";
import { useTheme } from '@material-ui/core/styles';

export const FrontGridOne: React.FC<FrontGridType> = ({
  items,
  sectionId
}) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const item1 = items.find((item)=>{return item.position === 1;});
  const item2 = items.find((item)=>{return item.position === 2;});
  const item3 = items.find((item)=>{return item.position === 3;});

  const item4 = items.find((item)=>{return item.position === 4;});

  if(isSmall){
    return (
      <Grid container  spacing={3}>
        <Grid item md={12}>
          <FrontContainerBox>
            {
              item2 ? (
                <FrontBoxItem 
                  item={item2}
                  paddingPaper={false}
                  onClick={()=>onClickPost(item2.postLink)} 
                  itemName='item2' 
                  sectionId={sectionId}
                />
              ):null

            }
          </FrontContainerBox>
          <FrontContainerBox>
            {
              item4 ? (
                <FrontBoxItem 
                  item={item4} 
                  paddingPaper={false} 
                  horizontal onClick={()=>onClickPost(item4.postLink)}
                  itemName='item4' 
                  sectionId={sectionId}
                />
              ):null
                
            }
          </FrontContainerBox>
          <FrontContainerBox >
            {
              item1 ?(
                <FrontBoxItem
                  item={item1}
                  paddingPaper={false}
                  onClick={()=>onClickPost(item1.postLink)}
                  itemName='item1'
                  sectionId={sectionId}
                />
              ):null
            }
          </FrontContainerBox>
          <FrontContainerBox>
            {
              item3 ? (
                <FrontBoxItem
                  item={item3}
                  paddingPaper={false}
                  onClick={()=>onClickPost(item3.postLink)}
                  itemName='item3'
                  sectionId={sectionId}
                />
              ):null

            }
          </FrontContainerBox>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container  spacing={3}>
      <Grid item md={4}>
        <FrontContainerBox >
          {
            item1 ?(
              <FrontBoxItem item={item1}  paddingPaper={false} onClick={()=>onClickPost(item1.postLink)}       
                itemName='item1'
                sectionId={sectionId}/>
            ):null
          } 
        </FrontContainerBox>
        <FrontContainerBox>
          {
            item3 ? (
              <FrontBoxItem item={item3} paddingPaper={false}  onClick={()=>onClickPost(item3.postLink)}   
                itemName='item3'
                sectionId={sectionId}/>
            ):null

          }    
        </FrontContainerBox>
  
      </Grid>
      <Grid item md={8}>
        <FrontContainerBox>
          {
            item2 ? (
              <FrontBoxItem 
                item={item2}  
                height={277} 
                paddingPaper={false} 
                onClick={()=>onClickPost(item2.postLink)}      
                itemName='item2'
                sectionId={sectionId}
              />
            ):null

          } 
        </FrontContainerBox>
        <FrontContainerBox>
          {
            item4 ? (
              <FrontBoxItem item={item4}  paddingPaper={false} horizontal onClick={()=>onClickPost(item4.postLink)}      itemName='item4'
                sectionId={sectionId}/>
            ):null
          }  
        </FrontContainerBox>
      </Grid>
    </Grid>
  );
};