import React,{ReactNode} from 'react';
import { Grid, makeStyles, Paper} from "@material-ui/core";
import {useDrop} from "react-dnd";

type PostsListViewProps = {
    children: ReactNode
}

const useStyles = makeStyles({
  root:{
    minHeight:'100%',
    height:'100%',
    maxHeight:'100%',
    overflow: 'auto'
  }
});

export const PostsListView: React.FC<PostsListViewProps> = (
  {children}
) =>{
  const  classes = useStyles();

  const [{canDrop, isOver}, drop] = useDrop({
    accept: 'box-item-type',
    drop: () => ({name: 'post-list', position: 0}),
    collect: (monitor: { isOver: () => any; canDrop: () => any; }) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div ref={drop}>
      <Paper elevation={1} classes={{
        root: classes.root
      }} square >
        <Grid 
          container style={{height:'760px'}} 
          direction='row'
        >
          {children}
        </Grid>
      </Paper>
    </div>

  );
};