import React from 'react';
import { toast } from 'react-toastify';
import { AlertBase } from '../alerts';

const config = {
  autoClose: 15000,
  hideProgressBar: true,
  position: toast.POSITION.BOTTOM_RIGHT,
};

/**
 * Toast to display messages in 'success' status anywhere in the application.
 *
 * @param title - Title of message.
 * @param description - Message.
 */
export const success = (title: string, description: string): void => {
  toast.success(
    <AlertBase type="success" title={title} description={description} />,
    config,
  );
};

/**
 * Toast to display messages in 'error' status anywhere in the application.
 *
 * @param description - Message.
 */
export const error = (description: string): void => {
  toast.error(
    <AlertBase type="error" title="ERROR" description={description} />,
    config,
  );
};

/**
 * Toast to display messages in 'info' status anywhere in the application.
 *
 * @param title - Title of message.
 * @param description - Message.
 */
export const info = (title: string, description: string): void => {
  toast.info(
    <AlertBase type="info" title={title} description={description} />,
    config,
  );
};

/**
 * Toast to display messages in 'warning' status anywhere in the application.
 *
 * @param title - Title of message.
 * @param description - Message.
 */
export const warn = (title: string, description: string): void => {
  toast.warn(
    <AlertBase type="warning" title={title} description={description} />,
    config,
  );
};
