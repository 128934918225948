import React,{ChangeEvent} from 'react';
import {Box, Paper , TextField } from "@material-ui/core";

type SectionNameProps ={
    name: string;
    onChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}

export const SectionName: React.FC<SectionNameProps> = (
  {name, onChange}
) =>{
  return (
    <Box mb={2}>
      <Paper elevation={1} square>
        <Box width='100%' p={2} textAlign='end'>
          <TextField
            name='sectionName'
            label={`Nombre de la sección`}
            type="text"
            color='secondary'
            value={name}
            variant="outlined"
            fullWidth
            size='small'
            onChange={onChange}
          />
        </Box>
      </Paper>
    </Box>
  );
};