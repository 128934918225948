import {AppSettings, PostType, SettingsType} from "./types";

export const postListRelatedSelectedList = (
  postList: Array<PostType>,
  selectedList: Array<PostType>,
  item: PostType,
  position: number
):{
  newPostList:PostType[],
  newSelectedList: PostType[]
} => {
    
  // find item for check if space its empty
  const findItem = selectedList.find((selected)=>{return selected.position === position;});

  if(findItem){
    // if found push to push post list
    const list = [...postList];
    list.splice(item.postIndex, 1);
    const gridList = [...selectedList].filter((selected)=>{return selected.position !== position;});

    return {
      newPostList: [...list, {...findItem, position:0}],
      newSelectedList: [...gridList,{...item, position}]
    };
  }
  
  const list = [...postList];
  const gridList = [...selectedList, {...item, position}];
  list.splice(item.postIndex, 1);

  return {
    newPostList: list,
    newSelectedList: gridList
  };
};

export const selectedListRelatedSelectedList = (
  selectedList: Array<PostType>,
  item: PostType,
  position: number
):{
  newSelectedList: PostType[]
} => {

  const findItem = selectedList.find((selected)=>{return selected.position === position;});

  if(findItem){

    const gridList = [...selectedList].filter((selected)=>{
      return selected.position !== findItem.position && selected.position !== item.position;
    });


    return {
      newSelectedList: [...gridList,{...findItem, position:item.position}, {...item, position: findItem.position}]
    };
  }
  const gridList = [...selectedList].filter((selected)=>{
    return selected.position !== item.position;
  });

  return {
    newSelectedList: [...gridList,{...item, position}]
  };

};

export const onClickPost = ( url: string)=>{
  window.location.href = url;
};