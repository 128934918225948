import React from "react";
import {Box, Grid} from "@material-ui/core";
import {FrontGridOne} from "../../shared/components/templates/front/FrontGridOne";
import {FrontSectionDataType} from "../../shared/types";
import {FrontGridTwo} from "../../shared/components/templates/front/FrontGridTwo";
import {FrontGridThree} from "../../shared/components/templates/front/FrontGridThree";
import {FrontGridFour} from "../../shared/components/templates/front/FrontGridFour";
import {FrontGridFive} from "../../shared/components/templates/front/FrontGridFive";
import {FrontGridSix} from "../../shared/components/templates/front/FrontGridSix";
import {FrontGridSeven} from "../../shared/components/templates/front/FrontGridSeven";

export const SectionView: React.FC<FrontSectionDataType> = ({
  data
})=>{
  return(
    <Box p={2} width='100%'>
      <Grid container spacing={3} style={{height: '100%'}}>
        <Grid item md={12}>
          {
            data.selectedGrid === 'grid-one'? (
              <FrontGridOne items={data.postItemsSelected} sectionId={data.sectionId}/>
            ): null
          }
          {
            data.selectedGrid === 'grid-two'?(
              (<FrontGridTwo items={data.postItemsSelected} sectionId={data.sectionId} />)
            ): null
          }
          {
            data.selectedGrid === 'grid-three'?(
              (<FrontGridThree items={data.postItemsSelected} sectionId={data.sectionId} />)
            ): null
          }
          {
            data.selectedGrid === 'grid-four'?(
              (<FrontGridFour items={data.postItemsSelected} sectionId={data.sectionId} />)
            ): null
          }
          {
            data.selectedGrid === 'grid-five'?(
              (<FrontGridFive items={data.postItemsSelected}  sectionId={data.sectionId} />)
            ): null
          }
          {
            data.selectedGrid === 'grid-six'?(
              (<FrontGridSix items={data.postItemsSelected}  sectionId={data.sectionId} />)
            ): null
          }
          {
            data.selectedGrid === 'grid-seven'?(
              (<FrontGridSeven items={data.postItemsSelected} sectionId={data.sectionId}  />)
            ): null
          }
        </Grid>
      </Grid>
    </Box>
  );
};