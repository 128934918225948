import {createEvent} from "@cobuildlab/react-simple-state";
import {PostType, SectionType} from "../../shared/types";

export const createSectionEvent = createEvent<{data: { message: string; success: boolean }}>();
export const createSectionErrorEvent = createEvent<Error>();

export const fetchSectionEvent = createEvent<{data: { posts:PostType[], type: string , sectionName: string}}>();
export const fetchSectionErrorEvent = createEvent<Error>();

export const updateSectionEvent = createEvent<{data: { message: string; success: boolean }}>();
export const updateSectionErrorEvent = createEvent<Error>();

export const deleteSectionEvent = createEvent<{data: { message: string; success: boolean }}>();
export const deleteSectionErrorEvent = createEvent<Error>();

export const fetchSectionListEvent = createEvent<{ data: { sectionList: SectionType[] , count: number}}>();
export const fetchSectionListErrorEvent = createEvent<Error>();
