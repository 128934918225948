import React from 'react';
import Grid, { GridProps } from '@material-ui/core/Grid';

interface ContainerProps extends GridProps {
    fill?: boolean;
}

/**
 * Generic object to define a row container.
 *
 * @param props - Props of Container.
 * @param props.fill - To fill the empty space around the container.
 * @returns The container component.
 */
export const Container: React.FC<ContainerProps> = (props) => {
  const rootProps = { ...props };
  if (props.fill !== undefined) rootProps.xs = true;

  return (
    <Grid container {...rootProps}>
      {props.children}
    </Grid>
  );
};
