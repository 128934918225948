import React, {ReactNode} from "react";
import {Tooltip} from "@material-ui/core";

type TooltipInfoProps = {
    children: React.ReactElement<any, any>;
    title: string;
    open?:boolean;
    click?: boolean;
}

export const TooltipInfo: React.FC<TooltipInfoProps> = (
  {children, title, click= false , open=false}
)=>{
    
  if(click){
    return(
      <Tooltip
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={title}
        aria-label="add"
        arrow
        open={open}
      >
        {children}
      </Tooltip>
    );
  }

  return(
    <Tooltip title={title} aria-label="add" arrow>
      {children}
    </Tooltip>
  );

};