import React, {ChangeEvent, useState} from 'react';
import {Box, Paper} from "@material-ui/core";
import {ButtonDefault} from "../buttons/buttons";
import {
  CANCEL_DIALOG_TEXT,
  CREATE_SECTION_TEXT,
  ON_YES_DIALOG_TEXT,
  CREATE_TITLE_TEXT,
  ON_YES_PROGRAM_DIALOG_TEXT, PROGRAM_TITLE_TEXT
} from "../../contansts";
import {DefaultDialogs} from "../dialogs/DefaultDialogs";
import { PostType, SettingsType} from "../../types";
import {createSection} from "../../../modules/section/section-actions";
import moment from 'moment';
import {useSubscription} from "@cobuildlab/react-simple-state";
import {createSectionEvent} from "../../../modules/section/section-events";
import TextField from '@material-ui/core/TextField';

type OptionsViewProps = {
    text?: string;
    textDialog?: string;
    onYesDialogText?: string;
    onYes: () => void;
    postPublish: any;
    setPostPublish: any;
    open: any;
    setOpen: any;
    isLoading: boolean
}

export const OptionsView: React.FC<OptionsViewProps> = (
  {
    text='Crear sección',
    textDialog= CREATE_SECTION_TEXT,
    onYesDialogText =   ON_YES_DIALOG_TEXT,
    postPublish,
    setPostPublish,
    onYes,
    open, 
    setOpen,
    isLoading
  }
)=>{

  const onHandleCloseCreateSection = (): void =>{
    setOpen(false);
  };

  const onCreateSection = (): void =>{
    setOpen(true);
  };

  const onChangeDate = (event:ChangeEvent): void =>{
    // @ts-ignore
    const {value} = event.target;
    setPostPublish(moment(value).format(moment.HTML5_FMT.DATETIME_LOCAL));
  };


  return (
    <>
      <Box mb={2}>
        <Paper elevation={1} square>
          <Box width='100%' p={2} textAlign='end'>
            <Box component='span' mr={2}>
              <TextField
                id="datetime-local"
                label="Hora de publicación"
                type="datetime-local"
                value={postPublish}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={onChangeDate}
              />
            </Box>
            <Box component='span' mt={1}>
              <ButtonDefault
                variant='contained'
                color='primary'
                onClick={onCreateSection}
              >{text}</ButtonDefault>
            </Box>

          </Box>
        </Paper>
      </Box>
      <DefaultDialogs 
        open={open} 
        onYesText={onYesDialogText}
        cancelText={CANCEL_DIALOG_TEXT}
        onYes={onYes}
        handleClose={onHandleCloseCreateSection}
        isLoading={isLoading}
        title={CREATE_TITLE_TEXT}
      >
        {textDialog}
      </DefaultDialogs>
    </>
  );
};