import React, {useEffect, useState} from "react";
import {AppSettings, SectionType} from "../../shared/types";
import MaterialTable from 'material-table';
import {Box} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import {tableIcons} from "../../shared/components/table/icons";
import {deleteSection, fetchSectionList} from "./section-actions";
import {useSubscription} from "@cobuildlab/react-simple-state";
import {fetchSectionListEvent, deleteSectionErrorEvent, deleteSectionEvent, updateSectionEvent} from "./section-events";
import {TablePagination} from "./components/TablePagination";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {TooltipInfo} from "../../shared/components/tooltip/TooltipInfo";
import {DefaultDialogs} from "../../shared/components/dialogs/DefaultDialogs";
import {CANCEL_DIALOG_TEXT, CREATE_TITLE_TEXT, ON_YES_DELETE_DIALOG_TEXT} from "../../shared/contansts";
import * as toast from "../../shared/components/toasts/Toast";

const useMDStyles = makeStyles({
  wrapperMaterialTable: {
    '& > .MuiPaper-root': {
      boxShadow: 'none',
    },
  },
  checkbox: {
    padding: '12px',
  },
  menu: {
    '& > .MuiPaper-root': {
      right: '110px!important',
      left: 'initial!important',
    },
    '& > .MuiPaper-root:hover': {
      color: 'white',
      backgroundColor: 'rgb(9, 167, 250)',
    },
  },
  copyClipboard:{
    backgroundColor:'#fafafa',
    padding:10,
    width:220,
    textAlign: 'center',
    cursor:'pointer'
  }
});

const initialFilter = {
  page: 0,
  perPage: 20
};

export const SectionListView: React.FC<AppSettings> = ({
  settings
}) =>{
  const classes = useMDStyles();
  const [sections, setSections] = useState<SectionType[]>([]);
  const [filter, setFilter] = useState<{page: number, perPage:number}>(initialFilter);
  const [count, setCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedSection, setSelectedSection] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);


  useEffect(()=>{
    fetchSectionList(settings, filter);
  },[settings, filter]);

  useSubscription(
    fetchSectionListEvent,(state)=>{
      if(state && state.data){
        setSections(state.data.sectionList);
        setCount(state.data.count);
        setIsLoading(false);
      }
    },undefined);

  useSubscription(
    deleteSectionEvent,
    (state)=>{
      if(state && state.data && state.data.success){
        toast.success('SUCCESS',state.data.message );
        setIsLoading(true);
        setOpen(false);
        setIsDeleteLoading(false);
        fetchSectionList(settings, filter);
      }
    },undefined);

  useSubscription(
    deleteSectionErrorEvent,
    (state)=>{
      if(state){
        toast.error('Error');
        setIsDeleteLoading(false);
      }
    }, undefined
  );

  const handlePage = (page: number): void =>{
    setFilter({...filter, page});
    setIsLoading(true);
  };

  const onCopy = (sectionId: string): void => {
    setSelectedSection(sectionId);
    setTimeout(() => {
      setSelectedSection('');
    }, 3000);
  };

  const onYesDeleteDialog = (): void =>{
    setIsDeleteLoading(true);
    deleteSection(settings,selectedSection);
  };

  const columns = settings.isAdmin ? [
    { title: 'Sección ID', field: 'sectionId' },
    { title: 'Nombre de la sección', field: 'sectionName' },
    { title: 'Shortcode', field: 'sectionType' , render: (rowData:SectionType)=>(
      <CopyToClipboard text={`[section-view section_id=${rowData.sectionId}]`} onCopy={()=>{onCopy(rowData.sectionId || '');}}>
        <div className={classes.copyClipboard}>
          <TooltipInfo title={'Copiado'} open={selectedSection === rowData.sectionId} click>
            <div>
              <strong>[</strong>{` section-view section_id=${rowData.sectionId} `}<strong>]</strong>
            </div>
          </TooltipInfo>
        </div>
      </CopyToClipboard>
    )
    },
    { title: 'Fecha de publicación', field: 'sectionPostPublish' },
  ] : [
    { title: 'Sección ID', field: 'sectionId' },
    { title: 'Nombre de la sección', field: 'sectionName' },
    { title: 'Fecha de publicación', field: 'sectionPostPublish' },
  ];

  console.log('list', sections);
  console.log('filter', filter);

  return (
    <Box width='100%' p={2} className={classes.wrapperMaterialTable}>
      <MaterialTable icons={tableIcons}
        isLoading={isLoading}
        columns={columns}
        data={sections}
        title="Lista de secciones"
        options={{
          actionsColumnIndex: -1,
          search:false,
          pageSize:filter.perPage
        }}
        actions={ settings.isAdmin ? [
          {
            icon: () => <tableIcons.Edit />,
            tooltip: 'Editar sección',
            onClick: (event, rowData: any) => {
              window.location.href = `${settings.homeUrl}/wp-admin/admin.php?page=cover-page-update-section&section_id=${rowData.sectionId}`;
            }
          },
          {
            icon: () => <tableIcons.Delete />,
            tooltip: 'Eliminar sección',
            onClick: (event, rowData: any) => {
              setSelectedSection(rowData.sectionId);
              setOpen(true);
            }
          }
        ] : [
          {
            icon: () => <tableIcons.Edit />,
            tooltip: 'Editar sección',
            onClick: (event, rowData: any) => {
              window.location.href = `${settings.homeUrl}/wp-admin/admin.php?page=cover-page-update-section&section_id=${rowData.sectionId}`;
            }
          }
        ]}
        localization={{
          header:{
            actions:"Acciones"
          }
        }}
        components={{
          Pagination: (propsPagination) => (
            <TablePagination
              {...propsPagination}
              count={count}
              rowsPerPage={filter.perPage}
              page={filter.page}
              onChangePage={
                handlePage ? (e, pag) => handlePage(pag) : undefined
              }
            />
          ),
        }}
      />
      <DefaultDialogs
        open={open}
        onYesText={ON_YES_DELETE_DIALOG_TEXT}
        cancelText={CANCEL_DIALOG_TEXT}
        onYes={onYesDeleteDialog}
        handleClose={()=> {
          setOpen(false);
          setSelectedSection('');
        }}
        isLoading={isDeleteLoading}
        title={CREATE_TITLE_TEXT}
      >
        ¿Estas seguro que deseas eliminar esta sección?
      </DefaultDialogs>
    </Box>
  );
};