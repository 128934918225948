import { createMuiTheme } from '@material-ui/core/styles';
import {
  PRIMARY_MAIN_COLOR,
} from './colors';

export default createMuiTheme({
  palette: {
    primary: {
      main: PRIMARY_MAIN_COLOR
    },
  },
  typography: {
    fontFamily: ['Roboto', 'Sans-serif'].join(','),
  },
});
