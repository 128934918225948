import styled from '@emotion/styled';
import { ToastContainer as RTToastContainer } from 'react-toastify';

/**
 * Custom styles for toast components that are dynamically added to the toast container.
 */
export const ToastContainer = styled(RTToastContainer)`
  width: 450px;
  max-width: 600px;
  box-shadow: none;

  .Toastify__toast {
    padding: 0px;
    border-radius: 8px;
    min-width: 450px;
    box-shadow: 0 9px 21px 0 rgba(171, 171, 171, 0.17);
    background-color: #f5fcff;
    border: 1px solid #09a7fa;
  }

  .Toastify__toast--success {
    background-color: #ecf9ed;
    border: 1px solid #00c6bd;
  }

  .Toastify__toast--error {
    background-color: #fdecea;
    border: 1px solid #fa336a;
  }

  .Toastify__toast--warning {
    background-color: #fff5ee;
    border: 1px solid #ff782a;
  }

  button[aria-label='close'] {
    color: #000;
    padding: 7px;
  }
`;
