import React from "react";
import {BoxContainer} from "./components/BoxContainer";
import {BoxItem} from "../../box/BoxItem";
import {GridRowType} from "../../types";
import {Grid} from "@material-ui/core";
import {SkeletonBox} from "./skeletons/SkeletonBox";

export const GridFive: React.FC<GridRowType> = ({
  items, onChangeGrid,name
}) => {

  const item1 = items.find((item)=>{return item.position === 1;});
  const item2 = items.find((item)=>{return item.position === 2;});
  const item3 = items.find((item)=>{return item.position === 3;});

  return (
    <Grid container  spacing={3}>
      <Grid item md={6}>
        <BoxContainer position={2} name={name}>
          {
            item2 ? (
              <BoxItem 
                item={item2} 
                onChangeGrid={onChangeGrid} 
                height={277} 
                paddingPaper={false}
                showCategory={false}
                showDescription={false}
              />

            ):(
              <SkeletonBox height={277}/>
            )
          }
        </BoxContainer>
      </Grid>
      <Grid item md={6}>
        <BoxContainer position={1} name={name}>
          {
            item1 ?(
              <BoxItem 
                item={item1} 
                onChangeGrid={onChangeGrid} 
                paddingPaper={false} 
                horizontal
                imageLeft={false}
              />
            ):(
              <SkeletonBox/>
            )
          }

        </BoxContainer>
        <BoxContainer position={3} name={name}>
          {
            item3 ? (
              <BoxItem 
                item={item3} 
                onChangeGrid={onChangeGrid} 
                paddingPaper={false} 
                horizontal
                imageLeft={false}
              />
            ):(
              <SkeletonBox/>
            )
          }
        </BoxContainer>

      </Grid>
    </Grid>
  );
};