import React from "react";
import {Grid, Box, Paper} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";

type SkeletonBoxProps = {
    width?: number,
    height?: number,
    horizontal?: boolean
}

export const SkeletonBox: React.FC<SkeletonBoxProps> = (
  {
    height = 132, 
    width ='100%',
    horizontal= false
  }
)=>{
  return(
    <Grid container>
      <Grid item md={12}>
        <Box>
          <Paper elevation={1} square>
            {
              !horizontal ? (
                <Box p={2}>
                  <Box mb={2}>
                    <Skeleton variant="rect" width={width} height={height} />
                  </Box>
                  <Box mb={1}>
                    <Skeleton width="40%" height={14} variant="rect"/>
                  </Box>
                  <Box mb={1}>
                    <Skeleton height={18} variant="rect"/>
                  </Box>
                  <Box mb={1}>
                    <Skeleton height={13} variant="rect"/>
                  </Box>
                  <Box mb={1}>
                    <Skeleton height={13} variant="rect"/>
                  </Box>
                </Box>
              ):(
                <Box p={2}>
                  <Grid container spacing={2}
                    direction='row'
                    justify='center'
                    alignItems='center'
                  >
                    <Grid item md={6}>
                      <Skeleton variant="rect" width={width} height={height} />
                    </Grid>
                    <Grid item md={6}>
                      <Box mb={1}>
                        <Skeleton height={18} variant="rect"/>
                      </Box>
                      <Box mb={1}>
                        <Skeleton height={13} variant="rect"/>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              )
            }
          </Paper>
        </Box>
      </Grid>
    </Grid>

  );
};