import React,{ChangeEvent} from 'react';
import {Box, Paper , TextField, InputAdornment } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';

type SearchPostsProps ={
    search: string;
    onChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}

export const SearchPosts: React.FC<SearchPostsProps> = (
  {search, onChange}
) =>{
  return (
    <Box mb={2}>
      <Paper elevation={1} square>
        <Box width='100%' p={2} textAlign='end'>
          <TextField
            name='search'
            label={`Buscar entradas`}
            type="text"
            color='secondary'
            value={search}
            variant="outlined"
            fullWidth
            size='small'
            onChange={onChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon fontSize='small'/>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Paper>
    </Box>
  );
};